import React from "react";
import styled, { createGlobalStyle } from "styled-components";

import banner from "./banner.m4v";
import graph from "./graph.gif";

import triangle from "./triangle.png";

import youtube from "./youtube24@2x.png";
import instagram from "./instagram24px@2x.png";
import twitter from "./twitter24px@2x.png";
import linkedin from "./linkedin24px@2x.png";
import facebook from "./facebook24px@2x.png";
import madurez from "./madurez.png";
import pilar from "./madurez.gif";
import logoVertical from "./logoVertical.png";
import logoHorizontal from "./logoHorizontal.png";
import gracias from "./gracias.png";
import bannerIcon from "./bannerIcon.png";

const GlobalStyle = createGlobalStyle`
  body{
    margin:0;
    padding:0;
    font-family: Cairo, Ubuntu, Prompt, sans-serif;
  }
`;

const PageContainer = styled.div`
  display: block;
  box-sizing: content-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  background-color: #2b233f;
  overflow-x: hidden;
`;

const IconBanner = styled("img")`
  height: 77px;
  width: 76px;
  @media (max-width: 700px) {
    height: 36px;
    width: 35px;
  }
  margin-right: 10px;
`;

const LogoHeader = styled("img")`
  width: 114.16px;
  height: 48px;
  position: absolute;
  top: 49px;
  @media (max-width: 1200px) {
    top: 10px;
  }
`;

const FlexBox = styled.div`
  display: flex;
  flex: 1;
`;

const Background = styled.article`
  width: 100%;
  background-color: #2b233f;
`;

const BackgroundBanner = styled(Background)`
  padding-bottom: 15%;
`;

const TitleBanner = styled.h1`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 96px;
  line-height: 110px;
  color: #f9f7ff;
  margin: 0;
  margin-left: 15px;
  margin-top: 20px;

  @media (max-width: 1200px) {
    font-size: 35px;
    line-height: 37px;
    margin-left: 0px;
  }
`;
const SubtitleBanner = styled.h2`
  font-family: Cairo;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 38px;
  margin: 0;
  color: #f9f7ff;
  @media (max-width: 1200px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

const Button = styled.button`
  background-color: #ff476c;
  width: 300px;
  height: 44px;
  outline: none;
  border: none;
  border: 0;
  box-sizing: border-box;
  cursor: pointer;

  &:hover,
  &:focus-visible {
    -webkit-transition: ease-in box-shadow 0.1s;
    transition: ease-in box-shadow 0.1s;
    box-shadow: 0 0.18762rem 0.3125rem 0 rgba(255, 68, 110, 0.6);
  }
  &:active {
    -webkit-transition: ease-in transform 0.1s;
    transition: ease-in transform 0.1s;
    transform: scale(0.94);
    border-color: transparent;
  }
`;

const TextButton = styled.p`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height, or 100% */

  text-align: center;
  letter-spacing: 1px;
  margin-top: 8px;
  margin-bottom: 8px;
  color: #ffffff;
`;

const ContainerTitleBanner = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonLanding = ({ text, ...props }) => (
  <Button {...props}>
    <TextButton>{text}</TextButton>
  </Button>
);

const DividerSubtitleBanner = styled.div`
  margin-top: 42px;
  padding-left: 110px;
  @media (max-width: 700px) {
    padding-left: 2%;
  }
`;
const DividerButton = styled.div`
  height: 65px;
`;

const ContainerTitle = styled(FlexBox)`
  flex-direction: row;
  align-items: baseline;

  @media (max-width: 700px) {
    margin-top: 15%;
  }
`;

const Video = styled.video`
  box-sizing: content-box;
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: auto;

  @media (max-width: 700px) {
    height: 100%;
  }
  @media (min-width: 1500px) {
    height: 100%;
  }
`;
const PaddingBanner = styled.div`
  @media (min-width: 1500px) {
    padding-left: 25%;
    padding-right: 25%;
  }

  @media (max-width: 700px) {
    padding-left: 5%;
    padding-right: 5%;
  }

  padding-left: 15%;
  padding-right: 15%;
  padding-top: 15%;

  position: relative;
  top: 40;
`;

const CenterBanner = styled(FlexBox)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 100%;
`;

const onScroll = (event) => {
  event.preventDefault();
  const postular = document.getElementById("postular");

  postular.scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "nearest",
  });
};

const Banner = () => {
  return (
    <BackgroundBanner>
      <Video id="video" src={banner} autoPlay loop playsinline muted />
      <PaddingBanner>
        <a href="https://www.option.cl">
          <LogoHeader src={logoHorizontal} alt="logoHorizontal" />
        </a>
        <CenterBanner>
          <ContainerTitleBanner>
            <ContainerTitle>
              <IconBanner src={bannerIcon} alt="iconBanner" />
              <TitleBanner>Madurez Digital</TitleBanner>
            </ContainerTitle>
            <DividerSubtitleBanner>
              <SubtitleBanner>
                Postula a un <b>diagnóstico gratuito</b> con nuestros expertos
                en Transformación Digital para lograr la <b>Madurez Digital</b>.
              </SubtitleBanner>
            </DividerSubtitleBanner>
          </ContainerTitleBanner>
          <DividerButton />

          <ButtonLanding text="POSTULAR" onClick={onScroll} />
        </CenterBanner>
      </PaddingBanner>
    </BackgroundBanner>
  );
};
const ContainerTitleDescription = styled(FlexBox)`
  margin-top: 5%;
  flex-wrap: wrap;
  max-width: 458px;
  z-index: 2;
`;

const TitleDescription = styled.h1`
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 49px;
  margin: 0;
  padding: 0;
  color: #ffffff;
  @media (max-width: 700px) {
    font-size: 32px;
    line-height: 38px;
    font-weight: normal;
  }
`;

const ContainerSubitleDescripTion = styled(FlexBox)`
  height: 150px;
  max-width: 458px;
  margin-top: 3%;
  margin-left: 7%;
  z-index: 2;
  @media (min-width: 1200px) {
    margin-bottom: 35%;
  }
  @media (max-width: 700px) {
    margin-left: 0px;
  }
`;

const SubTitleDescription = styled.p`
  font-family: Cairo;
  font-style: normal;
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  font-size: 18px;
  line-height: 22px;
  margin: 0;
  margin-right: 5px;
  color: #ffffff;
  @media (max-width: 700px) {
    line-height: 20px;
  }
`;

const TextSecondary = styled(SubTitleDescription)`
  color: #ff446e;
`;

const ContainerDescription = styled(FlexBox)`
  @media (min-width: 1500px) {
    padding-left: 25%;
    padding-right: 25%;
    position: relative;
  }

  flex-direction: column;
  padding-bottom: 5%;
  padding-left: 15%;
  padding-right: 15%;

  @media (max-width: 700px) {
    padding-left: 5%;
    padding-right: 5%;
    flex-wrap: wrap;
  }
  max-width: 100%;
`;
const ContainerSuperDescription = styled(ContainerDescription)`
  background-color: #2b233f;
  position: relative;
`;
const Graph = styled("img")`
  margin: 0;
  padding: 0;
  margin-bottom: 5%;
  width: 940px;
  height: 495.55px;

  @media (min-width: 1200px) {
    margin-top: 5%;

    position: absolute;
  }
  @media (max-width: 700px) {
    margin-top: 5%;
    width: 316.71px;
    height: 166.97px;
  }
`;
const ContainerGraph = styled(FlexBox)`
  flex-direction: column;
`;

const IconDescription = styled("img")`
  width: 160.53px;
  height: 137.08px;
  @media (max-width: 700px) {
    width: 140.53px;
    height: 120px;
  }
`;
const ContainerExample = styled(FlexBox)`
  flex-direction: row;
  align-items: center;
  margin-left: 10%;
  flex-wrap: wrap;
  @media (max-width: 700px) {
    justify-content: center;
    flex-direction: column;
    margin-left: 0px;
    padding-right: 5%;
  }
`;

const ContainerListExample = styled(FlexBox)`
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: 10%;

  @media (max-width: 700px) {
    margin-left: 0px;
    width: 100%;
  }
`;

const ListExample = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: 10%;
  height: 142px;
  @media (max-width: 700px) {
    width: 100%;
    margin-left: 5%;
  }
`;

const TitleExample = styled("h1")`
  font-family: Cairo;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #f9f7ff;
  @media (max-width: 700px) {
    font-weight: normal;
  }
`;

const List = styled("ul")`
  list-style: none;
  padding: 0;
`;

const ListItem = styled("li")`
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;

  color: #f9f7ff;

  margin-bottom: 15px;
  &::before {
    content: "•";
    color: #ff476c;
    display: inline-block;
    font-weight: 900;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-left: -1em;
  }
  @media (max-width: 700px) {
    font-weight: normal;
  }
`;

const ContainerSubitle = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: flex-start;
`;

const BackgroundDescription = styled(Background)`
  @media (max-width: 1200px) {
    padding-top: 10%;
    padding-bottom: 5%;
  }
`;

const Description = () => (
  <BackgroundDescription>
    <ContainerSuperDescription>
      <ContainerGraph>
        <ContainerTitleDescription>
          <TitleDescription>¿Qué es la </TitleDescription>
          <TitleDescription>MADUREZ DIGITAL?</TitleDescription>
        </ContainerTitleDescription>

        <ContainerSubitleDescripTion>
          <ContainerSubitle>
            <SubTitleDescription>La Madurez Digital </SubTitleDescription>{" "}
            <TextSecondary> es el cimiento tecnológico</TextSecondary>{" "}
            <SubTitleDescription>que las</SubTitleDescription>{" "}
            <SubTitleDescription>empresas necesitan</SubTitleDescription>
            <TextSecondary>para Transformarse Digitalmente.</TextSecondary>
            <SubTitleDescription>
              Con esto, nos referimos a las funcionalidades mínimas que requiere
              una empresa para empezar su proceso de Transformación Digital.
            </SubTitleDescription>
          </ContainerSubitle>
        </ContainerSubitleDescripTion>

        <Graph src={graph} alt="graph" />
      </ContainerGraph>
      <ContainerExample>
        <IconDescription alt="icono" src={madurez} />
        <ContainerListExample>
          <TitleExample>
            Ejemplos de servicios recurrentes requeridos en Madurez Digital:
          </TitleExample>
          <ListExample>
            <List>
              <ListItem>ERP</ListItem>
              <ListItem>CRM</ListItem>
              <ListItem>Marketing Digital</ListItem>
              <ListItem>Ecommerce</ListItem>
            </List>
            <List>
              <ListItem>Infraestructura</ListItem>
              <ListItem>Microsoft</ListItem>
              <ListItem>G-Suite</ListItem>
              <ListItem>Mucho más...</ListItem>
            </List>
          </ListExample>
        </ContainerListExample>
      </ContainerExample>
    </ContainerSuperDescription>
  </BackgroundDescription>
);

const BackgroundAbstract = styled(Background)`
  background-color: #fbf3f8;
  padding-bottom: 5%;
  @media (max-width: 700px) {
    padding-bottom: 15%;
  }
`;

const TitleAbstract = styled(TitleDescription)`
  color: ${({ secondary }) => (secondary ? "#ff446e" : "#000036")};
  font-weight: ${({ bold }) => (bold ? 900 : "normal")};
  @media (max-width: 700px) {
    font-size: 32px;
    line-height: 38px;
  }
`;

const ContainerItem = styled(FlexBox)`
  margin: 0;
  padding: 0;
  flex-direction: column;

  width: 277px;

  @media (max-width: 700px) {
    width: 100%;
    margin-top: 10%;
  }
`;

const TitleItem = styled.h2`
  margin: 0;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #000036;
`;

const DescriptionItem = styled.p`
  margin: 0;
  margin-top: 10px;
  font-family: Cairo;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  color: #000036;
`;
const Indicator = styled.div`
  width: 0;
  height: 0;
  border-left: 24px solid #ff446e;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  margin-right: -7px;
`;

const RowTitleContactItem = styled("div")`
  display: flex;
  flex-direction: row;
`;

const ItemAbstract = ({ title, description, style }) => (
  <ContainerItem style={style}>
    <RowTitleContactItem>
      <Indicator />
      <TitleItem>{title}</TitleItem>
    </RowTitleContactItem>
    <DescriptionItem>{description}</DescriptionItem>
  </ContainerItem>
);

const ListRowTitleContactAbstract = styled(FlexBox)`
  flex-direction: row;
  align-self: center;
  margin-top: 5%;
  margin-left: 5%;
  @media (max-width: 700px) {
    margin-left: 0;
    flex-wrap: wrap;
    flex-direction: column;
  }
`;

const ListColumnAbstract = styled(FlexBox)`
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  @media (max-width: 700px) {
    order: 2;
  }
`;

const ListCenterAbstract = styled(FlexBox)`
  margin-left: 0px;
  margin-right: 25px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 700px) {
    order: 1;
    margin-left: 0px;
    margin-right: 0px;
    align-items: flex-start;
  }
`;

const Pilar = styled.img`
  width: 390px;
  height: 270px;
  margin-bottom: 15%;
  @media (max-width: 700px) {
    width: 320px;
    height: 260px;
    margin-bottom: 0px;
  }
`;

const Abstract = () => {
  return (
    <BackgroundAbstract>
      <ContainerDescription>
        <ContainerTitleDescription>
          <TitleAbstract>Definimos </TitleAbstract>
          <TitleAbstract>la Madurez Digital en</TitleAbstract>
          <TitleAbstract secondary bold>
            5 Pilares.
          </TitleAbstract>
        </ContainerTitleDescription>
        <ListRowTitleContactAbstract>
          <ListColumnAbstract>
            <ItemAbstract
              title="Estrategia"
              description="Construir nuevos procesos que permitan transformar el negocio para aumentar su ventaja competitiva, a través de estrategias y tácticas digitales."
            />
            <ItemAbstract
              title="Cliente"
              description="Relación y percepción del cliente con la organización."
            />
          </ListColumnAbstract>
          <ListCenterAbstract>
            <Pilar src={pilar} alt="pilar" />
            <ItemAbstract
              title="Operaciones"
              description="Revisar y replantear los procesos y tareas según las tecnologías digitales disponibles que puedan impulsar una gestión estratégica."
            />
          </ListCenterAbstract>
          <ListColumnAbstract>
            <ItemAbstract
              title="Organización y cultura"
              description="Definir y desarrollar una estructura corporativa que provea lugares de trabajo abiertos y altamente creativos, donde se promueva el uso y la experimentación de nuevas tecnologías."
            />
            <ItemAbstract
              title="Tecnología y sistemas"
              description="Acompañan la estrategia digital, ayudando a crear, procesar, almacenar y dar seguridad al intercambio de data"
            />
          </ListColumnAbstract>
        </ListRowTitleContactAbstract>
      </ContainerDescription>
    </BackgroundAbstract>
  );
};

const Iframe = styled("iframe")`
  margin-left: 10%;
  margin-bottom: 5%;

  opacity: 0.8;
  width: 780px;
  height: 439px;
  @media (min-width: 1366px) {
    margin-bottom: 2%;
  }

  @media (max-width: 700px) {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    width: 100%;
    height: 176.86px;
  }
`;

const ContainerSutitleForm = styled(FlexBox)`
  flex-wrap: wrap;
  width: 450px;
  margin-top: 20px;
  margin-bottom: 40px;
  max-width: 100%;
`;

const TitleForm = styled("h1")`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #f9f7ff;
`;

const Label = styled.label`
  font-family: Cairo;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;

  letter-spacing: 1px;
  margin-left: 5px;
  color: #f9f7ff;
`;

const Input = styled.input`
  height: 42px;
  width: 100%;
  background-color: transparent;
  outline: none;
  border: none;
  border: 1px solid #ff446e;
  box-sizing: border-box;
  border-radius: 4px;

  color: #f9f7ff;

  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 5px;

  font-family: Cairo;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;

  &:hover {
    border: 2px solid #ff446e;
  }
`;

const ContainerField = styled(FlexBox)`
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

const Field = ({ label, containerStyle, ...props }) => (
  <ContainerField style={containerStyle}>
    <Label htmlFor={label}>{label}</Label>
    <Input {...props} />
  </ContainerField>
);

const Select = styled.select`
  outline: none;
  border: none;
  height: 42px;
  width: 100%;
  background-color: transparent;
  border: 1px solid #ff446e;
  box-sizing: border-box;
  border-radius: 4px;

  color: #f9f7ff;

  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 5px;

  font-family: Cairo;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
  background-image: url("${triangle}");

  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right 0.7em top 50%, 0 0;

  &:hover {
    border: 2px solid #ff446e;
  }
`;

const Option = styled.option`
  font-family: Cairo;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #2b233f;
  background-color: #f9f7ff;
  border: 0.5px solid #ff446e;
`;

const SelectField = ({ label, containerStyle, ...props }) => (
  <ContainerField style={containerStyle}>
    <Label htmlFor={label}>{label}</Label>
    <Select {...props}>
      <Option value="Minería, Energía e Industriales">
        Minería, Energía e Industriales
      </Option>
      <Option value="Consumo">Consumo</Option>
      <Option value="Tecnología, Medios y Telecomunicaciones">
        Tecnología, Medios y Telecomunicaciones
      </Option>
      <Option value="Sector Publ">Sector Público</Option>
      <Option value="Sector Financieros">Servicios Financieros</Option>
      <Option value="Salud">Servicios Financieros</Option>
    </Select>
  </ContainerField>
);

const IndicatorAbstract = styled.div`
  width: 0;
  height: 0;
  border-left: 12px solid #00a3b5;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  margin-right: 1rem;
`;

const Form = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 100%;
`;

const CenterContact = styled(FlexBox)`
  margin-top: 30px;
  flex-direction: column;
  align-self: center;
  width: 620px;
  @media (max-width: 700px) {
    width: 100%;
    max-width: 100%;
  }
`;

const CenterContactButton = styled(CenterContact)`
  align-items: center;
`;

const RowTitleContact = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  @media (max-width: 700px) {
    align-items: baseline;
  }
`;

const TitleContact = styled(TitleDescription)`
  font-weight: normal;
  @media (max-width: 700px) {
    font-size: 32px;
    line-height: 49px;
  }
`;

const initialState = {
  job: "",
  rubro: "",
  numberOfWorkers: 0,
  url: "",
  name: "",
  email: "",
};

const reducer = (state, action) => {
  if (action.type === "reset") {
    return initialState;
  }

  const result = { ...state };
  result[action.type] = action.value;
  return result;
};

const Contact = ({ onContact }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { job, rubro, numberOfWorkers, url, name, email } = state;
  const Body = `
      <html>
      <body>
      <h2>Formulario de contacto</h2>
      <ul>
        <li>Nombre de la empresa: ${job}</li>
        <li>Rubro de la empresa: ${rubro}</li>
        <li>Cantidad de trabajadores: ${numberOfWorkers}</li>
        <li>Sitio web: ${url}</li>
        <li>Nombre de contacto: ${name}</li>
        <li>Correo electrónico: ${email}</li>
      </ul>
      </body>
      </html`;

  const handleLoaded = (_) => {
    window.grecaptcha.ready((_) => {
      window.grecaptcha.execute("6Lc2hd8ZAAAAALXNf0J3A1g1k36Vzl5QkK_1QNhb", {
        action: "homepage",
      });
    });
  };

  React.useEffect(() => {
    // Add reCaptcha
    const script = document.createElement("script");
    script.src =
      "https://www.google.com/recaptcha/api.js?render=6Lc2hd8ZAAAAALXNf0J3A1g1k36Vzl5QkK_1QNhb";
    script.addEventListener("load", handleLoaded);
    document.body.appendChild(script);
  }, []);

  const handleSubmit = React.useCallback(
    async (e) => {
      e.preventDefault();
      if (
        job === "" ||
        url === "" ||
        name === "" ||
        email === "" ||
        numberOfWorkers === 0
      ) {
        return alert("Complete todos los campos, por favor");
      }
      await window.grecaptcha.ready(function () {
        window.grecaptcha.execute("6Lc2hd8ZAAAAALXNf0J3A1g1k36Vzl5QkK_1QNhb", {
          action: "submit",
        });
      });

      await window.Email.send({
        SecureToken: "7cf7b5bb-5bf3-4f53-b7e8-aa4d60b309d3",
        To: "ravp92@gmail.com",
        From: "ravp92@gmail.com",
        Subject: "Contacto",
        Body,
      });
      onContact(false);
      dispatch({ type: "reset" });
    },
    [dispatch, onContact, job, url, numberOfWorkers, email, name, Body]
  );

  const onChange = React.useCallback(
    (e) => {
      e.preventDefault();
      const { name, value } = e.target;
      dispatch({ type: name, value });
    },
    [dispatch]
  );

  return (
    <Background>
      <ContainerDescription>
        <ContainerTitleDescription>
          <TitleContact>Pasos del proceso</TitleContact>
        </ContainerTitleDescription>
        <ContainerSutitleForm>
          <SubTitleDescription>
            Descubre qué obtendrás luego de la postulación exitosa y cómo sacar
            el mayor provecho de los resultados.
          </SubTitleDescription>
        </ContainerSutitleForm>

        <Iframe
          title="diagnostico de madurez"
          src="https://www.youtube.com/embed/hNI-lWjqHqo"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />

        <RowTitleContact>
          <IndicatorAbstract />
          <TitleForm id="postular">Postula a un diagnóstico gratuito</TitleForm>
        </RowTitleContact>
        <CenterContact>
          <Form onSubmit={handleSubmit}>
            <Field
              label="Nombre de la Empresa"
              onChange={onChange}
              value={job}
              name="job"
            />
            <SelectField
              label="¿A qué se dedica la Empresa?"
              onChange={onChange}
              value={rubro}
              name="rubro"
            />
            <Field
              label="Cantidad de trabajadores"
              onChange={onChange}
              value={numberOfWorkers}
              name="numberOfWorkers"
              type="number"
              min={0}
            />
            <Field
              label="Sitio web"
              onChange={onChange}
              value={url}
              name="url"
            />
            <Field
              label="Nombre de contacto"
              onChange={onChange}
              value={name}
              name="name"
              type="text"
            />
            <Field
              label="Correo electrónico"
              onChange={onChange}
              value={email}
              name="email"
              type="email"
            />
            <CenterContactButton>
              <ButtonLanding
                className="g-recaptcha"
                data-sitekey="6Lc2hd8ZAAAAALXNf0J3A1g1k36Vzl5QkK_1QNhb"
                data-callback="handleSubmit"
                data-action="submit"
                data-theme="dark"
                text="POSTULAR"
                type="submit"
                onClick={handleSubmit}
              />
            </CenterContactButton>
          </Form>
        </CenterContact>
      </ContainerDescription>
    </Background>
  );
};
const BackgroundMessage = styled(Background)`
  background-image: url("${gracias}");
  background-size: 100% 90%;
  background-repeat: no-repeat;
  background-position: center;
`;

const CenterMessage = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding-top: 25%;
  padding-bottom: 20%;
`;

const TitleMessage = styled.h1`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 55px;
  text-align: center;
  margin: 0;
  color: #f9f7ff;
`;

const SubTitleMessage = styled(TitleMessage)`
  font-weight: normal;
  font-size: 42px;
  line-height: 48px;

  max-width: 807px;
  width: 100%;
`;
const BannerTitleMessage = styled(SubTitleMessage)`
  font-weight: bold;
  margin-left: 10px;
`;

const TextMessage = styled.p`
  margin: 0;
  font-family: Cairo;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;

  text-align: center;

  color: #f9f7ff;
`;
const ContainerTextMessage = styled("div")`
  margin-top: 3%;
  margin-bottom: 2%;
`;
const BannerMessage = styled("div")`
  top: 50px;
  left: 15%;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Message = () => {
  React.useEffect(() => window.scrollTo({ top: 0 }), []);
  return (
    <BackgroundMessage>
      <BannerMessage>
        <IconBanner src={bannerIcon} height={41} width={40} alt="iconBanner" />
        <BannerTitleMessage>Madurez Digital</BannerTitleMessage>
      </BannerMessage>
      <CenterMessage>
        <TitleMessage>Hemos recibido tus datos con éxito.</TitleMessage>
        <SubTitleMessage>
          Nuestro equipo se pondrá en contacto contigo para continuar el
          proceso.
        </SubTitleMessage>
        <ContainerTextMessage>
          <TextMessage>
            ¿Deseas saber más sobre nosotros y lo que hacemos?
          </TextMessage>
          <TextMessage>Haz click a continuación.</TextMessage>
        </ContainerTextMessage>

        <a href="https://www.option.cl">
          <ButtonLanding text="IR A OPTION" />
        </a>
      </CenterMessage>
    </BackgroundMessage>
  );
};

const BackgroundFooter = styled.footer`
  background: #3b3b5a;
  height: 227px;
  width: 100%;
  @media (max-width: 700px) {
    background: #000036;
    height: 316px;
  }
`;

const CityText = styled.h3`
  font-family: Prompt;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  text-align: center;
  letter-spacing: 1px;
  margin-right: 10px;
  margin-left: 10px;
  color: #f3faff;
`;

const ContainerFooter = styled(FlexBox)`
  flex-direction: row;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  margin-left: 15%;
  margin-right: 15%;

  @media (max-width: 700px) {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 10%;
    flex-direction: column;

    width: 100%;
  }
`;

const LogoFooter = styled("img")`
  width: 51px;
  height: 73px;
`;
const LogoMedia = styled("img")`
  width: 24px;
  height: 24px;
  margin-right: 15px;
`;

const ListMedia = styled(FlexBox)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  max-width: 216px;
  margin: 0;
  padding: 0;
`;
const Divider = styled.div`
  @media (max-width: 700px) {
    height: 40px;
  }
`;
const Footer = () => (
  <BackgroundFooter>
    <ContainerFooter>
      <Divider />
      <a href="https://www.option.cl">
        <LogoFooter src={logoVertical} alt="logoVertical" />
      </a>
      <CityText>SANTIAGO | MIAMI | LIMA | STOCKHOLM</CityText>
      <ListMedia>
        <a href="https://www.youtube.com/channel/UC9caH0ua-zdOzTUhmaH-2Ew">
          <LogoMedia src={youtube} alt="youtube" />
        </a>
        <a href="https://www.facebook.com/option.cl/">
          <LogoMedia src={facebook} alt="facebook" />
        </a>
        <a href="https://twitter.com/OptionTI">
          <LogoMedia src={twitter} alt="twitter" />
        </a>
        <a href="https://www.instagram.com/we_love_option/">
          <LogoMedia src={instagram} alt="instagram" />
        </a>
        <a href="https://www.linkedin.com/company/option/">
          <LogoMedia src={linkedin} alt="linkedin" />
        </a>
      </ListMedia>
    </ContainerFooter>
  </BackgroundFooter>
);

const App = () => {
  const [isContact, setContact] = React.useState(true);
  return (
    <React.Fragment>
      <GlobalStyle />
      <PageContainer>
        {isContact ? (
          <React.Fragment>
            <Banner />
            <Description />
            <Abstract />
            <Contact onContact={setContact} />
            <Footer />
          </React.Fragment>
        ) : (
          <Message />
        )}
      </PageContainer>
    </React.Fragment>
  );
};
// yarn add webfontloader
export default App;
